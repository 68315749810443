import React, { useEffect, useState } from "react";
import "../Components/Css/main.css";
import Aboutus from "./Aboutus";
import Services from "./Services";
import Testimonial from "./Testimonial";
import Blog from "./Blog";
import Contactus from "./Contactus";
import Skills from "./Skills";
import { Fade } from "react-awesome-reveal";
import Project from "./Project";

const Main = () => {
  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };
  const styles = {
    // fontSize: '24px', 
    color: '#fff',
  };
  const words = ['Design', 'Solution', 'Customization', 'Development', 'Support', 'Maintenance', 'Integration'];
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(100);

  useEffect(() => {
    let typingInterval;

    if (isDeleting) {
      typingInterval = setTimeout(() => {
        setDisplayedText(prev => prev.slice(0, -1));
        if (displayedText === '') {
          setIsDeleting(false);
          setCurrentWordIndex((prev) => (prev + 1) % words.length);
        }
      }, typingSpeed);
    } else {
      typingInterval = setTimeout(() => {
        setDisplayedText(prev => words[currentWordIndex].slice(0, prev.length + 1));
        if (displayedText === words[currentWordIndex]) {
          setTimeout(() => setIsDeleting(true), 2000);
        }
      }, typingSpeed);
    }

    return () => clearTimeout(typingInterval);
  }, [displayedText, isDeleting, currentWordIndex, typingSpeed]);

  return (
    <>

      <div className='allcourse-header' id="Home" >
        <div className='container'>
          <div className='row parrent' style={{ alignItems: "center" }} >
            <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12'>

              <div className='ph' style={styles} >
                Creative Design  and
                <div>
                  <span style={{ textDecoration: "underline" }}> Web  </span>
                  <span style={{ textDecoration: 'underline', color: "#FF6B00" }}>
                    {displayedText}
                  </span>
                </div>

              </div>

              <Fade direction="up">
                <div className='pt mt-5' style={{ color: "#EAECF0" }}>
                  At CodeInitial Web Solutions, we specialize in creating custom digital experiences that are tailored to meet the unique needs of your business.
                  Our expertise in web design, development, and customization ensures that your product is not only visually stunning but also highly functional and user-friendly.


                </div>
              </Fade>




              <div className='mt-5'>
                <button className="orange-btn" onClick={() => scrollToSection('Contact')}>Contact Us</button>
              </div>



            </div>

            <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12' >
              <img src="https://img.freepik.com/free-photo/person-front-computer-working-html_23-2150040428.jpg?t=st=1718705068~exp=1718708668~hmac=e1b10474ef285a966eb697a83ac8f38ce786d4deada55a4dbf5ac5421090f8d6&w=996" className="img-fluid " style={{ borderRadius: "50%" }} alt="" />
            </div>

          </div>

        </div>
      </div>
      <div > <Aboutus /></div>
      <div><Skills /></div>
      <div > <Services /></div>
      <div><Project /></div>
      <div > <Testimonial /></div>
      <div > <Blog /></div>
      <div><Contactus /></div>

    </>
  );
};

export default Main;
