import React, { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import Card from "../Components/Common/Card";
import Offcanvas from 'react-bootstrap/Offcanvas';

const Project = () => {
    const [show, setShow] = useState(false);
    const [currentOffcanvas, setCurrentOffcanvas] = useState(null);

    const handleClose = () => {
        setShow(false);
        setCurrentOffcanvas(null);
    };

    const handleShow = (card) => {
        setShow(true);
        setCurrentOffcanvas(card);
    };

    const cardData = [
        {
            title: "Online Learning Management System (LMS)",
            text: "Our Online Learning Management System (LMS) is a comprehensive platform designed to facilitate and enhance the online learning experience. It allows users to purchase courses and access educational content seamlessly.",
            description: {
                overview: "Our Online Learning Management System (LMS) is a comprehensive platform designed to facilitate and enhance the online learning experience. It allows users to purchase courses and access educational content seamlessly. With a user-friendly interface, robust course management features, and secure payment integration, our LMS ensures an optimal learning environment for students and an efficient management system for educators.",
                features: [
                    "Course Purchase and Enrollment: Users can browse available courses, view detailed descriptions, and purchase courses using a secure payment gateway. Once enrolled, users gain immediate access to course materials.",
                    "Online Reading and Course Material Access: Courses include various types of educational content such as videos, articles, and quizzes. Users can read and interact with the course material directly within the platform.",
                    "User Dashboard: Each user has a personalized dashboard to track their course progress, manage their enrollments, and view their learning history.",
                    "Responsive Design: The platform is designed to be fully responsive, providing an optimal learning experience on both desktop and mobile devices.",
                    "Admin Panel: A powerful admin panel allows educators and administrators to manage courses, track student progress, and handle administrative tasks efficiently."
                ],
                technologyStack: [
                    "Frontend: Built with React.js for a dynamic and responsive user interface.",
                    "Backend: Node.js and Express.js for a scalable and robust server-side architecture.",
                    "Database: MongoDB for flexible and efficient data storage.",
                    "Payment Integration: Secure payment processing using Stripe.",
                    "Authentication: Secure user authentication and authorization with JWT."
                ],
                benefits: [
                    "Convenience: Enables users to learn at their own pace and from any location.",
                    "Scalability: Designed to handle a growing number of users and courses without compromising performance.",
                    "Engagement: Interactive course materials and progress tracking keep users motivated and engaged."
                ],
                futureEnhancements: [
                    "Community Features: Adding forums and discussion boards for user interaction.",
                    "Certifications: Providing certification upon course completion to recognize user achievements.",
                    "Multimedia Support: Expanding content types to include live webinars and interactive simulations."
                ]
            },
            image: "https://img.freepik.com/free-vector/online-job-interview_23-2148613123.jpg?t=st=1720507148~exp=1720510748~hmac=f2455ac313a00efc8ca4752d3890e975af3f7c55b7d38ba519c0e9b56e18749a&w=996",
            link: "https://learnlytica.com/",
            showButton: true
        },

    ];

    return (
        <>
            <div className='container ' id="Project" style={{ padding: "65px 0px " }}>
                <div className='features-screen p-5'>
                    <div className='title'>
                        <div className="line"></div>
                        <Fade delay={1000} cascade damping={0.1}>
                            <h4>My Project</h4>
                        </Fade>
                        <div className="line"></div>
                    </div>
                    <Slide>
                        <h2 className='feature-screen-header'>Latest Project</h2>
                    </Slide>
                </div>
                <div className="container mt-4">
                    <div className="row">
                        {cardData.map((card, index) => (
                            <div className="col-md-4 mb-4" key={index}>
                                <Fade direction="left" delay="0">
                                    <Card
                                        title={card.title}
                                        text={card.text}
                                        link={card.link}
                                        image={card.image}
                                        showButton={card.showButton}
                                        click={() => handleShow(card)}
                                    />
                                </Fade>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <Offcanvas show={show} onHide={handleClose} Size="sm">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="card-title">{currentOffcanvas && currentOffcanvas.title}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-bg p-5">
                    {currentOffcanvas && (
                        <>


                            <div className="project-feature"><h6>Overview:</h6></div>
                            <div className="card-text" style={{ textAlign: "justify" }}> {currentOffcanvas.description.overview}</div>

                            <div className="project-feature"><h6>Features:</h6></div>
                            <ul className="offcanvas-list">
                                {currentOffcanvas.description.features.map((feature, index) => (
                                    <li className="card-text" style={{ textAlign: "justify" }} key={index}>{feature}</li>
                                ))}
                            </ul>

                            <div className="project-feature"><h6>Technology Stack:</h6></div>
                            <ul className="offcanvas-list">
                                {currentOffcanvas.description.technologyStack.map((tech, index) => (
                                    <li className="card-text" style={{ textAlign: "justify" }} key={index}>{tech}</li>
                                ))}
                            </ul>


                            <div className="project-feature"><h6>Benefits:</h6></div>
                            <ul className="offcanvas-list">
                                {currentOffcanvas.description.benefits.map((benefit, index) => (
                                    <li className="card-text" style={{ textAlign: "justify" }} key={index}>{benefit}</li>
                                ))}
                            </ul>

                            <div className="project-feature"><h6>Future Enhancements:</h6></div>
                            <ul className="offcanvas-list">
                                {currentOffcanvas.description.futureEnhancements.map((enhancement, index) => (
                                    <li className="card-text" style={{ textAlign: "justify" }} key={index}>{enhancement}</li>
                                ))}
                            </ul>
                        </>
                    )}
                </Offcanvas.Body>
            </Offcanvas>

        </>
    );
};

export default Project;
