import React from "react";
import { Fade, Slide } from "react-awesome-reveal";

const Aboutus = () => {
  return (
    <>
    <div className='container' id="About" style={{padding: "65px 0px "}}>

    <div className='features-screen p-5'>

    <div class='title'>
    <div class="line"></div>
   <Fade delay={1e3} cascade damping={1e-1}> <h4>About Us</h4></Fade>
    <div class="line"></div>
</div>

     
      <div className='row '>
        <Slide> <h2 className='feature-screen-header'>We Can Design Anything You Want</h2> </Slide>
          
                <Fade direction="right">  <div className='feature-screen-text mt-4'>At CodeInitial Web Solutions, we specialize in creating custom digital experiences tailored to your business needs. Our talented team of designers and developers can bring any vision to life, whether it's a modern website or an interactive platform. We focus on delivering solutions that are not only visually stunning but also highly functional and user-friendly. Utilizing cutting-edge technologies, we ensure that every element is optimized for engagement and ease of use. If you can dream it, we can design it. Let us help you create a standout digital experience. Contact us today to make your vision a reality</div>
                </Fade>
      


      </div>

      <div className='row'>
      <div className='col-xl-5 col-lg-5 col-md-5 col-sm-12 course-feature-img' >

         <img style={{mixBlendMode:"multiply"}}src="https://img.freepik.com/free-vector/forming-team-leadership-concept-illustration_114360-13973.jpg?w=996&t=st=1718717066~exp=1718717666~hmac=82d3715b65455858776642e57c14826cc8cf8d2622cc1f3e52bf1b094826a1fb" className="img-fluid" alt=""/>

      </div>
        <div className='col-xl-7 col-lg-7 col-md-7 col-sm-12'>
          <div className='row pt-5'>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
              <div className='pb-5' >
                    <h5 className='f1h1 pt-3'>Tailored Digital Solutions</h5> 
            <Fade direction="left">  <p className='f1p1'>Our team excels in crafting custom digital experiences that perfectly match your business needs, from sleek modern websites to complex interactive platforms.


            </p> </Fade> 
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
              <div className='pb-5' >
                  <h5 className='f1h1 pt-3'>Innovative and Cutting-Edge</h5>  
               <Fade direction="right">  <p className='f1p1'>
               We use the latest technologies and design principles to ensure your project is both aesthetically stunning and technically advanced, setting you apart in a competitive market.
              </p> </Fade>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
              <div className='pb-5' >
                  <h5 className='f1h1 pt-3'>User-Centric Design</h5>
                <Fade direction="left">   <p className='f1p1'>
                We prioritize user experience in our designs, ensuring that every element is optimized for maximum engagement and ease of use, resulting in a highly functional and user-friendly product.
             </p></Fade>
              </div>
            </div>
            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-6'>
              <div className='pb-5' >
               
                 <h5 className='f1h1 pt-3'>Collaborative Approach</h5>
              <Fade direction="right"><p className='f1p1'>
              We work closely with you from concept to completion, ensuring your vision is fully realized and your expectations are exceeded, delivering a final product that truly reflects your brand.
                  </p></Fade>
              </div>
            </div>
          </div>
        </div>


       

      </div>


    </div>


  </div>
    </>
  );
};

export default Aboutus;
