import React from "react";
import Card from "../Components/Common/Card";
import { Fade, Slide } from "react-awesome-reveal";

const Services = () => {
  const cardData = [
    {
      title: "Web Developer",
      text: "Building dynamic, responsive websites that provide a seamless user experience.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceFour.png",
      inlineStyle: { textAlign: "center" },
    },
    {
      title: "SEO Optimization",
      text: "Enhancing your website's visibility on search engines to attract more organic traffic.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceFive.png",
      inlineStyle: { textAlign: "center" },
    },
    {
      title: "UI/UX Design",
      text: "Creating intuitive and engaging interfaces that ensure a positive user experience.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceOne.png",
      inlineStyle: { textAlign: "center" },
    },
    {
      title: "WordPress Developer",
      text: "Custom WordPress solutions for your business needs, from themes to plugins.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceSix.png",
      inlineStyle: { textAlign: "center" },
    },
    {
      title: "App Development",
      text: "Developing robust mobile applications that enhance user engagement and business growth.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceSeven.png",
      inlineStyle: { textAlign: "center" },
    },
    {
      title: "Domain Registration",
      text: "Secure your online presence with our reliable domain registration services.",
      icon: "https://credesign.vercel.app/onePage/onePageOne/img/serviceEight.png",
      inlineStyle: { textAlign: "center" },
    },
  ];

  return (
    <>
      <div className='container ' id="Services" style={{ padding: "65px 0px " }}>

        <div className='features-screen p-5'>

          <div class='title'>
            <div class="line"></div>
            <Fade delay={1e3} cascade damping={1e-1} >
              <h4> Services</h4>
            </Fade>
            <div class="line"></div>
          </div>
          <Slide>
            <h2 className='feature-screen-header'>Services We Offer</h2>
          </Slide>
        </div>




        <div className="container mt-4">
          <div className="row">
            {cardData.map((card, index) => (
              <div className="col-md-3 mb-4" key={index}>
                <Card
                  icon={card.icon}
                  title={card.title}
                  text={card.text}
                  link={card.link}
                  inlineStyle={card.inlineStyle}
                //   date={card.date}
                //   image={card.image}

                />
              </div>
            ))}
          </div>
        </div>





















      </div>

    </>
  );
};

export default Services;
