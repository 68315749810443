import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
const Skills = () => {
  return (
    <>
    <div className="container" style={{padding: "65px 0px "}}>
    <div className='features-screen p-5'>

    <div class='title'>
    <div class="line"></div>
    <Fade delay={1e3} cascade damping={1e-1} >
    <h4>My Skills</h4>
    </Fade>
    <div class="line"></div>
</div>
<Slide >
<h2 className='feature-screen-header'>Software Skills</h2> 
</Slide>
    <div class="logos mt-5">
    <div class="logos-slide">
    <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" alt='icon' />
    <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg" alt='icon' />
   
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/bootstrap/bootstrap-plain-wordmark.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/c/c-original.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg" alt='icon' />
      <img src="https://camo.githubusercontent.com/f32e9cca1f0df0138a8f536217daa54ad21b6913642422f32e3c5c623f3a06b9/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6669676d612f6669676d612d69636f6e2e737667" alt='icon' />
      <img src="https://camo.githubusercontent.com/fcafa5ebc1f5f789ae7d012a3ecd8fe7bda49516591caf7c37698f764165d880/68747470733a2f2f7777772e766563746f726c6f676f2e7a6f6e652f6c6f676f732f6769742d73636d2f6769742d73636d2d69636f6e2e737667" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/linux/linux-original.svg" alt='icon' />
      
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mongodb/mongodb-original-wordmark.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg" alt='icon' />
      <img src="https://camo.githubusercontent.com/b05ddbfbaa85c1b814c44a6853f95899cf7f7a0f68ed9d4de9ab8e8b60f5608a/68747470733a2f2f63646e2e776f726c64766563746f726c6f676f2e636f6d2f6c6f676f732f6e6578746a732d322e737667" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg" alt='icon' />
      <img src="https://raw.githubusercontent.com/devicons/devicon/master/icons/sass/sass-original.svg" alt='icon' />
     
    </div>

    


  </div>
</div>
  </div>  
    </>
  );
};

export default Skills;
