import COMPANY_LOGO from "../Assets/Images/Asset 9.png"

const GLOBELCONSTANT = {
 
    BRANDING: {
        TITLE: "CodeInitial",
        TYPE: "Assessments",
        ICON: <img src={COMPANY_LOGO} alt=""  height="50px" />
    },

    ROLE: {
        ADMIN: "ADMIN",
        USER: "USER",

    },

   
}
export default GLOBELCONSTANT;