import React, { useEffect, useState } from "react";

const StickyHeader = ({headerclass,children}) => {
    const [isScrolled, setIsScrolled] = useState(false);
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 20) {
            setIsScrolled(true);
          } else {
            setIsScrolled(false);
          }
        };
    
        // Add event listener
        window.addEventListener('scroll', handleScroll);
    
        // Cleanup function to remove the event listener
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);


  return (
<>
 <div className={`${headerclass} ${isScrolled && 'sticky-div'}`} style={{
            borderBottom: "1px solid rgb(234, 236, 240)",

            background: "rgb(249, 250, 251)"
          }}

          >
          {children}
          </div>

</>

  );
};

export default StickyHeader;
