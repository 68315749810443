import React from "react";
import Card from "../Components/Common/Card";
import { Fade, Slide } from "react-awesome-reveal";

const Blog = () => {
    const cardData = [
        {
          title: "Enhancing Web Design with CSS Pseudo-Elements and Pseudo-Classes",
          text: "In this blog, we'll dive into the world of CSS pseudo-elements and pseudo-classes to elevate the design and interactivity of your web pages.  ",
        image:"https://images.ctfassets.net/qqwc8vzzdbmu/43HlhIC5KRNdYKZqR9lAFv/fdd498f3367be3f1b1b6663d82dfdaa1/programming-background-with-person-working-with-codes-computer.jpg",
        link:"https://learnlytica.com/blog/IsiKam1TVhlVG5lYzH9Ks/Web%20and%20Mobile%20Development"
    },
        
      ];
  return (
    <>
    <div className='container ' id="Blog" style={{padding: "65px 0px "}}>

    <div className='features-screen p-5'>

    <div class='title'>
    <div class="line"></div>
    <Fade delay={1e3} cascade damping={1e-1}>
    <h4>My Blog</h4>
    </Fade>
    <div class="line"></div>
</div>
<Slide>
<h2 className='feature-screen-header'>Latest Blog</h2>
</Slide> 
</div>




<div className="container mt-4">
      <div className="row">
        {cardData.map((card, index) => (
          <div className="col-md-4 mb-4" key={index}>
           <Fade direction="up" delay="0"> <Card
           title={card.title}
           text={card.text}
           link={card.link}
           date={card.date}
           image={card.image}
         //   icon={card.icon}
         /></Fade>
          </div>
        ))}
      </div>
    </div>
                      




















</div>
    
    </>
  );
};

export default Blog;
