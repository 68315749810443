import React from "react";
import { Link } from "react-router-dom";
import GLOBELCONSTANT from "../../Constant/Globalconstant";



const Footer = () => {
 

 

  return (
    <>
     
      <footer class="footer-section">
        <div class="container">
          <div class="footer-cta pt-5 pb-5">
          
          </div>
          <div class="footer-content pt-5 pb-5">
           
          </div>
        </div>
        <div class="copyright-area">
          <div class="container">
            <div class="row">
              <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 text-lg-left">
             <span>{GLOBELCONSTANT.BRANDING.ICON}</span>
              </div>
              <div className="col-xl-4 col-lg-4 col-sm-12 col-md-4 ">
                <div class="footer-menu">
                  <ul>
                  <li>
                  <Link
                    to="https://github.com/m-ksingh"
                    target="blank"
                  >
                    Github
                  </Link>
                </li>
                    <li>
                      <Link
                        to="https://www.linkedin.com/in/mayank-kr-singh?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app"
                        target="blank"
                      >
                        Linkedin
                      </Link>
                    </li>
                  
                    <li>
                      <Link
                        to="https://www.instagram.com/m_k.singh/"
                        target="blank"
                      >
                        Instagram
                      </Link>
                    </li>
                    {/* <li><a href="#">AngelList</a></li> */}
                    {/* <li><a href="#">Dribbble</a></li> */}
                  </ul>
                </div>
              </div>

              <div class="col-xl-4 col-lg-4 col-sm-12 col-md-4 text-right">
                <div class="copyright-text">
                  <p> &copy; 2024 CodeInitial. All rights reserved </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
