import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const Card = ({ title, text, link, date, image, icon, inlineStyle, handleShow, showButton, click }) => {
  const handleButtonClick = () => {
    console.log("Button clicked");
    handleShow();
  };

  return (
    <div className="card pmd-card card-main h-100">
      {image && <div className='pmd-card-media'><img src={image} className="card-img-top" alt={title} /></div>}
      <div className="card-body">
        {icon && <span className="card-icon align-items-center d-flex justify-content-center"><img src={icon} alt={title} /></span>}
        <h2 className="card-title mt-3" style={inlineStyle}>{title}</h2>
        <div className="card-text mt-3" style={inlineStyle}>{text}</div>
        {date && <div className="card-date">{date}</div>}
        <div className='mt-3 mb-3 d-flex justify-content-between'>
          {link && <Link to={link} className='card-link ' target='_blank'>Read more</Link>}

          {showButton && (
            <Button variant="secondary" onClick={click} className="mt-2">More Info</Button>
          )}
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  link: PropTypes.string,
  date: PropTypes.string,
  image: PropTypes.string,
  icon: PropTypes.element,
  inlineStyle: PropTypes.object,
  handleShow: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
};

export default Card;
