import React from "react";
import "./Components/Css/main.css";
import NavBar from "./Components/Common/Navbar";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Main from "./Screen/Main";
import Footer from "./Components/Common/Footer";
import CustomCursor from "./Components/Common/CustomCursor";
import PageNotFound from "./Screen/PageNotFound";

function App() {
   return (
      <Router>
         <CustomCursor />
         <Layout />
      </Router>
   );
}

function Layout() {
   const location = useLocation();
   const showHeaderFooter = location.pathname === "/";

   return (
      <>
         {showHeaderFooter && <NavBar />}
         <Routes>
            <Route path="/" element={<Main />} />
            <Route path="*" element={<PageNotFound />} />
         </Routes>
         {showHeaderFooter && <Footer />}
      </>
   );
}

export default App;
