import React, { useState } from "react";
import { Fade, Slide } from "react-awesome-reveal";
import ButtonLoader from "../Components/Common/ButtonLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contactus = () => {
  // const AppId = process.env.REACT_APP_SHEET_ID
  const [buttonLoader, setButtonLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonLoader(true)
    const response = await fetch('https://api.sheetmonkey.io/form/hGEvy12P9bJ9sqirF3vkK2', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });

    if (response.ok) {
      // Handle success (optional)
      setTimeout(() => {
        setButtonLoader(false);
        setFormData({
          name: '',
          email: '',
          phone: '',
          subject: ''
        });
        toast("Data Submitted Successfully");
      }, 1000);

    } else {
      // Handle error
      setButtonLoader(false)
      toast.warn("Try Again");
      console.error('Form submission failed:', response.status);
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container" id="Contact" style={{ padding: "65px 0px " }}>
        <div className="features-screen p-5">
          <div className="title">
            <div className="line"></div>
            <Fade delay={1000} cascade damping={0.1}>
              <h4>My Contact</h4>
            </Fade>
            <div className="line"></div>
          </div>
          <Slide>
            <h2 className="feature-screen-header">I Want to Hear From You</h2>
          </Slide>
        </div>

        <div className="signup-form">
          <div className="row">
            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <div className="form-group">
                    <label>First Name<sup style={{ fontSize: "14px" }}>*</sup></label>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="First Name"
                        required
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <label>Email<sup style={{ fontSize: "14px" }}>*</sup></label>
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Email Address"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Phone Number<sup style={{ fontSize: "14px" }}>*</sup></label>
                  <div className="input-group">
                    <input
                      type="text"
                      inputMode="numeric"
                      className="form-control"
                      name="phone"
                      placeholder="Enter your Number"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Subject</label>
                  <div className="input-group">
                    <textarea
                      type="text"
                      rows="10"
                      cols="5"
                      className="form-control"
                      name="subject"
                      placeholder="Write your Subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button
                    type="submit"
                    style={{ width: "100%" }}
                    className="orange-btn btn-lg"
                  >{buttonLoader ?
                    <ButtonLoader loadingMessage="wait..." />
                    : 'Submit'}</button>
                </div>
              </form>
            </div>

            <div className="col-md-6 col-lg-6 col-xl-6 col-sm-12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contactus;
