import React, { useState } from "react";
import GLOBELCONSTANT from "../../Constant/Globalconstant";
import { Link, } from "react-router-dom";
import MenuIcon from '@mui/icons-material/Menu';
import StickyHeader from "./StickyHeader";

const NavBar = () => {

  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('Home');

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleSetActiveSection = (sectionId) => {
    setActiveSection(sectionId);
    document.getElementById(sectionId).scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <StickyHeader headerclass="header-container">

      <nav className="navigation">
        <div className="nav_toggle" onClick={toggleMenu}>
          <MenuIcon fontSize="large" />
        </div>

        <ul className={`nav_menu ${menuOpen ? "active" : ""}`}>
          <li className="nav_list">
            <Link onClick={() => handleSetActiveSection('Home')} >

              <span className="mx-2">{GLOBELCONSTANT.BRANDING.ICON}</span>
            </Link>
          </li>
          <li className="nav_list">
            <Link onClick={() => handleSetActiveSection('About')} className={activeSection === 'About' ? 'nav_link active' : 'nav_link'}>
              <span>About</span>
            </Link>
          </li>
          <li className="nav_list">
            <Link onClick={() => handleSetActiveSection('Services')} className={activeSection === 'Services' ? 'nav_link active' : 'nav_link'}>
              <span>Services</span>
            </Link>
          </li>
          <li className="nav_list">
            <Link onClick={() => handleSetActiveSection('Project')} className={activeSection === 'Project' ? 'nav_link active' : 'nav_link'}>
              <span>Project</span>
            </Link>
          </li>

          <li className="nav_list">
            <Link onClick={() => handleSetActiveSection('Blog')} className={activeSection === 'Blog' ? 'nav_link active' : 'nav_link'}>
              <span>Blog</span>
            </Link>
          </li>


        </ul>

        <div className="nav_action">
          <button className="orange-btn" onClick={() => handleSetActiveSection('Contact')}>Contact Us</button>
        </div>
      </nav>

    </StickyHeader>
  );
};

export default NavBar;
